'use client';
import { CloseRounded, Menu } from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Image from 'next/image';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';

import menuStyles from './menu.module.css';
/**
 * Menu content component
 * @returns React component
 */
export function MenuContent() {
  return (
    <>
      <Button
        label="Request a demo"
        pt={{
          root: 'w-full lg:w-auto',
        }}
        rounded
        icon={<AlternateEmailIcon fontSize="small" className="mr-0.5rem" />}
      />
    </>
  );
}

/**
 * Header component
 * @returns React component
 */
export function Header() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showMobileMenu]);

  return (
    <nav className="flex flex-col lg:flex-row items-center justify-between container mx-auto ">
      <div className="flex items-center gap-1 w-full lg:w-auto justify-between lg:justify-normal">
        <Image
          src="/images/logo-horizontal.svg"
          alt="GuestStay"
          width={250}
          height={200}
        />
        <Button
          icon={<Menu />}
          text
          severity="info"
          plain
          className="lg:hidden"
          aria-label="Toggle mobile menu"
          onClick={() => setShowMobileMenu(true)}
        />
      </div>

      {/* Desktop Menu */}
      <div
        className={`items-center gap-2rem w-full lg:flex lg:justify-end hidden`}
      >
        {/*<ThemeSwitcher tooltipPosition="bottom" />*/}
        <MenuContent />
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          menuStyles.mobileMenuWrapper
        } items-center gap-2rem w-full ${showMobileMenu ? 'block' : 'hidden'}`}
      >
        <div
          className={`bg-black bg-opacity-50 fixed top-0 left-0 -z-50 w-full h-full`}
          onClick={() => setShowMobileMenu(false)}
        />

        <div
          className={`${menuStyles.mobileMenu} flex flex-col justify-between gap-2rem bg-white dark:bg-slate-800 dark:border-slate-800 border-t-2 p-1rem h-dvh z-50`}
        >
          <div className="flex flex-col">
            <MenuContent />
          </div>

          <div className="flex flex-col gap-1rem">
            <div className="flex flex-col gap-0.5rem px-1rem w-full mb-1rem">
              <label className="font-bold">Change Theme</label>
              {/*<ThemeSwitcher tooltipPosition="bottom" showLabel />*/}
            </div>
            <Button
              label="Close"
              onClick={() => setShowMobileMenu(false)}
              severity="info"
              pt={{
                root: 'w-full',
              }}
              icon={<CloseRounded />}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
